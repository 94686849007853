import { h } from 'preact';
import classNames from 'classnames';

import SVG from '../../../../components/common/SVG';
import Intl from '../../../../components/common/intl';
import TextComponent from '../../../../components/common/textComponents';
import PremiumBenefits from './premiumBenefits';

import slideStyle from '../slides.scss';
import style from './style.scss';

const PremiumOnboarding = () => (
  <div className={slideStyle.slide}>
    <div className={classNames(slideStyle.imageContainer, style.premiumSlide)}>
      <SVG src="/assets/svg/grouped_premium.svg" />
    </div>
    <div className={slideStyle.textContainer}>
      <div>
        <TextComponent element="h4" center>
          <Intl textKey="I18N_PREMIUM_ONBOARD_PAGE_TITLE">
            Control our fan cam to take private group photos and purchase to:
          </Intl>
        </TextComponent>
      </div>
      <PremiumBenefits />
    </div>
  </div>
);

export default PremiumOnboarding;

import { h } from 'preact';
import classNames from 'classnames';

import faImage from '@fortawesome/fontawesome-pro/svgs/solid/image.svg';
import faDownload from '@fortawesome/fontawesome-pro/svgs/solid/download.svg';
import faFist from '@fortawesome/fontawesome-pro/svgs/solid/fist-raised.svg';
import FaIcon from '../../../../../components/common/faIcon';
import TextComponent from '../../../../../components/common/textComponents';

import Intl from '../../../../../components/common/intl';
import style from './style';

const PremiumBenefinits = () => (
  <div className={style.benefitsList}>
    <div className={style.listItem}>
      <div className={style.iconContainer}>
        <FaIcon icon={faImage} size="lg" />
      </div>
      <TextComponent element="p">
        <Intl textKey="I18N_PREMIUM_ONBOARD_REMOVE_WATERMARK">
          Remove watermark
        </Intl>
      </TextComponent>
    </div>
    <div className={style.listItem}>
      <div className={style.iconContainer}>
        <FaIcon icon={faFist} size="lg" />
      </div>
      <TextComponent element="p">
        <Intl textKey="I18N_PREMIUM_ONBOARD_PREMIUM_FRAMES">
          Add premium frames
        </Intl>
      </TextComponent>
    </div>
    <div className={style.listItem}>
      <div className={style.iconContainer}>
        <FaIcon icon={faDownload} size="lg" />
      </div>
      <TextComponent element="p">
        <Intl textKey="I18N_PREMIUM_ONBOARD_SAVE_MOMENT">
          Save your moment
        </Intl>
      </TextComponent>
    </div>
    <div className={classNames(style.listItem, style.noMargin)}>
      <TextComponent element="p">
        <Intl textKey="I18N_PREMIUM_ONBOARD_PURCHASE_OPTIONAL">
          * Purchase is optional
        </Intl>
      </TextComponent>
    </div>
  </div>
);

export default PremiumBenefinits;

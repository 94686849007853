import { h, Fragment } from 'preact';
import { connect } from 'redux-zero/react';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { useRef, useState, useEffect } from 'preact/hooks';
import Match from 'preact-router/match';

import TermsModal from '../../components/termsModal';
import Header from '../../components/header';
import Button from '../../components/common/button';
import Intl from '../../components/common/intl';
import EventGuard from '../../components/eventGuard';
import Dots from './components/reactCarouselDots';
import PremiumSlide from './components/premiumSlide';
import StadiumSlide from './components/stadiumSlide';
import PrivacySlide from './components/privacySlide';

import { route } from '../../helpers/router';
import { sendLog } from '../../helpers/apis/logger';
import { isPreviewMode } from '../../helpers/preview';

import style from './style';

let timeout;

const handleTouchmove = e => e.preventDefault();

const Onboarding = ({
  isTermsAccepted,
  geolocationEnabled,
  geofence,
  payment,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [shouldAutoplay, setShouldAutoplay] = useState(true);
  const carouselRef = useRef();
  const slidesNumber = payment.enabled ? 3 : 2;

  const onClickHandler = () => {
    if (!isTermsAccepted) {
      route('/terms');
    } else if (geofence.enabled && !geolocationEnabled) {
      route('/location');
    } else {
      route('/findme');
    }
  };

  const logSlideChange = (value, type) => {
    const context = {
      type: 'slideChange',
      toSlide: value,
      transitionType: type,
    };
    sendLog('info', context);
  };

  const onChangeHandler = (value) => {
    logSlideChange(value, 'triggered');
    setCurrentSlide(value);
    if (shouldAutoplay) {
      clearTimeout(timeout);
      setShouldAutoplay(false);
    }
  };

  useEffect(() => {
    window.addEventListener('touchmove', handleTouchmove, { passive: false });
    return () => {
      window.removeEventListener('touchmove', handleTouchmove, { passive: false });
    };
  }, [carouselRef]);

  const autoplayHandler = () => {
    if (shouldAutoplay) {
      timeout = setTimeout(() => {
        setCurrentSlide(1);
        logSlideChange(1, 'auto');

        if (payment.enabled) {
          timeout = setTimeout(() => {
            setCurrentSlide(2);
            logSlideChange(2, 'auto');
            setShouldAutoplay(false);
          }, 5000);
        } else {
          setShouldAutoplay(false);
        }
      }, 5000);
    }
  };

  return (
    <Fragment>
      <Header />
      <div className={style.page}>
        <div className={style.sliderContainer}>
          <Carousel
            ref={carouselRef}
            centered
            onChange={value => onChangeHandler(value)}
            value={currentSlide}
            keepDirectionWhenDragging
            animationSpeed={800}
            className={style.slider}
          >
            <StadiumSlide onImgLoad={autoplayHandler} />
            <PrivacySlide />
            {payment.enabled ? <PremiumSlide /> : null}
          </Carousel>
          <Dots
            number={slidesNumber}
            value={currentSlide}
            onChange={value => onChangeHandler(value)}
          />
        </div>
        <Button primary next onClick={onClickHandler}>
          <Intl textKey="I18N_NEXT_BUTTON">
            Next
          </Intl>
        </Button>
      </div>
      <Match path="/terms">
        {({ matches }) => matches && <TermsModal onClose={() => window.history.back()} />}
      </Match>
    </Fragment>
  );
};

const ConnectedComponent = connect(
  ({
    event: { geofence, payment },
    termsAccepted,
    termsModalOpen,
    geolocationEnabled,
  }) => ({
    geofence,
    payment,
    isTermsAccepted: termsAccepted,
    isTermsModalOpen: termsModalOpen,
    geolocationEnabled,
  }),
)(Onboarding);

export default (props) => {
  if (isPreviewMode()) {
    return <ConnectedComponent {...props} />;
  }

  return (
    <EventGuard>
      <ConnectedComponent {...props} />
    </EventGuard>
  );
};

import { h } from 'preact';
import Intl, { translate } from '../../../common/intl';

export const getTermsLink = () => translate('I18N_TERMS_MODAL_TOS_LINK', 'https://brizicam.com/terms/');
export const getPrivacyLink = () => translate('I18N_TERMS_MODAL_PRIVACY_POLICY_LINK', 'https://brizicam.com/privacy/');

export const TermsLabel = () => (
  <Intl textKey="I18N_TERMS_MODAL_TOS">Terms of service</Intl>
);

export const PrivacyLabel = () => (
  <Intl textKey="I18N_TERMS_MODAL_PRIVACY_POLICY">Privacy policy</Intl>
);
